$color-text: #333333;
$body-max-width: 960px;

$padding-mobile: 2rem 1rem;

* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.6rem;
    color: $color-text;
    background-color: #333333;
}

h1, h2, h3 {
    margin-bottom: 1rem;
    line-height: 1.2em;
}

h1 {
    letter-spacing: 3px;
    text-transform: uppercase;
}

h2, h3 {
    margin-top: 3rem;
    letter-spacing: 0.5px;
}

p:not(:last-child) {
    margin-bottom: 1rem;
}

header {
    background-image: url( '/images/banners/banner-2.jpg' );
    background-position: center;
    background-size: cover;
    padding: 5rem 0 0 0;

    svg {
        display: block;
        max-width: 200px;
        margin: 0 auto;
        position: relative;
        top: 48px;
        background-color: #FFFFFF;
        padding: 5px;
        /* box-shadow: 15px 15px 15px rgba(0,0,0,0.35); */
    }

}

.body {
    max-width: $body-max-width;
    margin: 60px auto 0 auto;
    padding: $padding-mobile;
}

a { color: teal; }

hr {
    margin: 1rem 0;
    border-top: 1px solid $color-text;
}

.text-center { text-align: center; }
.bg-white { background-color: #FFFFFF; }

.tag-cloud {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;

    li {
        padding: 6px 14px;
        background: papayawhip;
        background: linear-gradient(150deg, rgba(255,239,213,0.5) 80%, rgba(224,211,188,1) 100%);
        border-radius: 12px 0;
    }

    li:hover {
        background: linear-gradient(150deg, rgba(255,239,213,1) 80%, rgba(204,191,168,1) 100%);
        // cursor: pointer;
    }

}

footer {
    padding: $padding-mobile;
    color: #FFFFFF;

    a { color: gold; }

    .links {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 65%;
    }

}

@media ( min-width: 600px ) {

    .body {
        margin: 160px auto 0 auto;
        padding: 3rem 0 5rem 0;
    }

    header {
        padding: 5rem 0 0 0;

        svg {
            max-width: 460px;
            top: 120px;
            padding: 10px;
        }
    }

    h1 {
        letter-spacing: 5px;
    }

    .tag-cloud {
        gap: 8px;
    }

    footer {
        padding: 3rem;

        .links {
            flex-direction: row;
            gap: 1rem;
        }

    }

}
